/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';

// Layout


// Components


// Vendor

// Extras
@import 'components/shame';
